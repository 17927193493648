import { get, map, forEach, pick, some } from 'lodash'
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'
// api
import { FindReservationParameter, CheckReservation, ReservationOrderCalculateCheckoutPrice } from '@/api/reservation'
import { GetDepositConfig, GetDepositPayment } from '@/api/deposit'
import { GetServiceUnit } from '@/api/serviceUnit'
// utils
import { isInvalidDate } from '@/utils/date'
// others
import { OrderDeposit } from '@wishcbg/bob-appointment-deposit-package'
import dayjs from '@/lib/dayjs'
import store from '@/store'

export default {
  data: () => ({
    loading: false,
    depositConfig: {},
    depositPaymentConfig: [],
    config: {},
    memberData: {},
    mainServiceData: {},
    otherServiceData: [],
    otherUserData: [],
    invoiceData: {},
    noteData: {},
    depositPayment: '', // 選擇的訂金付款方式
    selectCheckoutPayment: '', // 選擇的結帳付款方式
    // 訂單結帳結果資料
    orderCheckout: {
      price: 0,
      data: {},
    },
    // 訂單訂金結果資料
    orderDeposit: {
      need: false,
      price: 0,
    },
    form: {
      member: null,
      mainService: null,
      otherService: null,
      deposit: null,
      checkout: null,
      classTicket: null,
      note: null,
      invoice: null,
    },
    allServiceUnitList: [],
  }),
  computed: {
    ...mapGetters(['userFeatures', 'userPlanFeature']),
    queryMember () {
      return this.$route.query.member
    },
    shopId () {
      return this.$store.getters.shop
    },
    peopleCount () {
      return get(this.memberData, 'peopleCount') || 1
    },
    useDeposit () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'adminView.appointmentDeposit.enable')
    },
    compactOrder () {
      const isMember = get(this.memberData, 'isMember')
      return {
        reservations: this.compactReservation,
        userType: isMember ? 'member' : 'none',
        memberId: isMember ? get(this.memberData, 'member.id', undefined) : undefined,
        userName: isMember ? get(this.memberData, 'member.UserInfo.name', undefined) : get(this.memberData, 'name'),
        userPhone: isMember ? get(this.memberData, 'member.UserInfo.phone', undefined) : get(this.memberData, 'phone'),
        peopleCount: isMember || 1,
      }
    },
    canOrderOtherService () {
      if (!this.config.allowOtherReservation) return false
      if (this.peopleCount > 1) return false
      if (!this.mainServiceData.otherService) return false
      return true
    },
    compactReservation () {
      const isMember = get(this.memberData, 'isMember')
      const memberName = isMember ? get(this.memberData, 'member.UserInfo.name') : get(this.memberData, 'name')
      const memberPhone = isMember ? get(this.memberData, 'member.UserInfo.phone') : get(this.memberData, 'phone')
      const date = dayjs(this.mainServiceData.date).format('YYYY-MM-DD')
      const time = this.mainServiceData.time || '00:00'
      // let time = dayjs(this.mainServiceData.time).format('HH:mm')
      // if (isInvalidDate(time)) time = this.mainServiceData.time
      const start = dayjs(`${date} ${time}`).toDate()
      let allowAppointmentUnitIds = null
      if (get(this.mainServiceData, 'serviceUnit.id', '')) {
        if (get(this.mainServiceData, 'serviceUnit.id', '') === 'all') allowAppointmentUnitIds = map(this.allServiceUnitList, 'id')
        else if (get(this.mainServiceData, 'serviceUnit.id', '') === 'notSpecify') allowAppointmentUnitIds = map(this.config.NotSpecifyUnits, 'id')
        else allowAppointmentUnitIds = [get(this.mainServiceData, 'serviceUnit.id', '')]
      }
      const main = {
        allowAppointmentUnitIds: allowAppointmentUnitIds,
        allowResourceItemIds: get(this.mainServiceData, 'resourceItemId.id') ? [get(this.mainServiceData, 'resourceItemId.id')] : [],
        // appointmentUnitId: get(this.mainServiceData, 'serviceUnit.id', '') || null,
        // resourceItemId: get(this.mainServiceData, 'resourceItemId.id') || get(this.mainServiceData, 'resourceItem.id'),
        appointmentServiceId: get(this.mainServiceData, 'service.id', ''),
        appointmentSubServiceId: get(this.mainServiceData, 'subService.id') || undefined,
        appointmentServiceAttachIds: map(this.mainServiceData.attachServices, 'id'),
        start,
        userName: memberName || undefined,
        userPhone: memberPhone || undefined,
        isOvertime: this.mainServiceData.isOverTime,
        // totalBookingTime: this.mainServiceData.totalTime,
        isOther: false,
        classTicketRecordId: get(this.mainServiceData, 'classTicket.id'),
        setTotalBookingTime: get(this.mainServiceData, 'totalTime') || null, // number or null
        setResourceItemTotalBookingTime: get(this.mainServiceData, 'resourceTotalTime') || null, // number or null
        // fromNotSpecify: false,
      }

      const others = []
      // 單人多服務
      if (this.peopleCount === 1 && this.canOrderOtherService) {
        forEach(this.otherServiceData, other => {
          let time = dayjs(other.time).format('HH:mm')
          if (isInvalidDate(time)) time = other.time
          let otherAllowAppointmentUnitIds = null
          if (get(other, 'serviceUnit.id', '')) {
            if (get(other, 'serviceUnit.id', '') === 'all') otherAllowAppointmentUnitIds = map(this.allServiceUnitList, 'id')
            else if (get(other, 'serviceUnit.id', '') === 'notSpecify') otherAllowAppointmentUnitIds = map(this.config.NotSpecifyUnits, 'id')
            else otherAllowAppointmentUnitIds = [get(other, 'serviceUnit.id', '')]
          }
          others.push({
            allowAppointmentUnitIds: otherAllowAppointmentUnitIds,
            allowResourceItemIds: get(other, 'resourceItemId.id') ? [get(other, 'resourceItemId.id')] : [],
            // appointmentUnitId: get(other, 'serviceUnit.id', '') || null,
            // resourceItemId: get(other, 'resourceItemId.id') || get(other, 'resourceItem.id'),
            appointmentServiceId: get(other, 'service.id', ''),
            appointmentSubServiceId: get(other, 'subService.id') || undefined,
            appointmentServiceAttachIds: map(other.attachServices, 'id') || [],
            userName: memberName || undefined,
            userPhone: memberPhone || undefined,
            start: dayjs(`${date} ${time}`).toDate(),
            isOvertime: other.isOverTime,
            isOther: true,
            // totalBookingTime: other.totalTime,
            setTotalBookingTime: other.totalTime || null,
            setResourceItemTotalBookingTime: other.resourceTotalTime || null,
            classTicketRecordId: get(other, 'classTicket.id'),
            // fromNotSpecify: false,
          })
        })
      }
      // 多人單服務
      if (this.peopleCount > 1 && this.config.allowOrderMorePeople) {
        for (let count = 0; count < this.peopleCount - 1; count++) {
          const other = this.otherUserData[count]
          others.push({
            allowAppointmentUnitIds: allowAppointmentUnitIds,
            allowResourceItemIds: get(this.mainServiceData, 'resourceItemId.id') ? [get(this.mainServiceData, 'resourceItemId.id')] : [],
            // appointmentUnitId: get(this.mainServiceData, 'serviceUnit.id', ''),
            // resourceItemId: get(this.mainServiceData, 'resourceItemId.id') || get(this.mainServiceData, 'resourceItem.id'),
            appointmentServiceId: get(this.mainServiceData, 'service.id', ''),
            appointmentSubServiceId: get(this.mainServiceData, 'subService.id') || undefined,
            appointmentServiceAttachIds: map(this.mainServiceData.attachServices, 'id') || [],
            start,
            userName: get(other, 'name') || undefined,
            userPhone: get(other, 'phone') || undefined,
            isOvertime: this.mainServiceData.isOverTime,
            // totalBookingTime: this.mainServiceData.totalTime,
            setTotalBookingTime: get(this.mainServiceData, 'totalTime') || null, // number or null
            setResourceItemTotalBookingTime: get(this.mainServiceData, 'resourceTotalTime') || null, // number or null
            isOther: false,
            // classTicketRecordId
            // fromNotSpecify: false,
          })
        }
      }
      if (!others.length) return [main]
      return [main, ...others]
    },
    AppointmentOrder () {
      const main = {
        AppointmentService: {
          id: get(this.mainServiceData, 'service.id'),
          price: get(this.mainServiceData, 'service.price'),
          AppointmentSubService: null,
        },
        AppointmentServiceAttaches: map(get(this.mainServiceData, 'attachServices'), i => ({
          id: i.id,
          price: i.price,
        })),
        isUseClassTicket: !!get(this.mainServiceData, 'classTicket.id'),
      }

      // 如果有選擇子服務
      if (get(this.mainServiceData, 'subService')) {
        main.AppointmentService.AppointmentSubService = get(this.mainServiceData, 'subService', null)
        // main.AppointmentService.id = get(this.mainServiceData, 'subService.id')
        // main.AppointmentService.price = get(this.mainServiceData, 'subService.price')
      }

      const others = []
      // 單人多服務
      if (this.peopleCount === 1 && this.canOrderOtherService) {
        forEach(this.otherServiceData, other => {
          let serviceId = get(other, 'service.id')
          let servicePrice = get(other, 'service.price')

          if (other.subService) {
            serviceId = get(other, 'subService.id')
            servicePrice = get(other, 'subService.price')
          }

          others.push({
            AppointmentService: {
              id: serviceId,
              price: servicePrice,
            },
            AppointmentServiceAttaches: map(other.attachServices, i => ({
              id: i.id,
              price: i.price,
            })),
            isUseClassTicket: !!get(other, 'classTicket.id'),
          })
        })
      }
      // 多人單服務
      if (this.peopleCount > 1 && this.config.allowOrderMorePeople) {
        for (let count = 0; count < this.peopleCount - 1; count++) {
          const data = {
            AppointmentService: {
              id: get(this.mainServiceData, 'service.id'),
              price: get(this.mainServiceData, 'service.price'),
              AppointmentSubService: null,
            },
            AppointmentServiceAttaches: map(get(this.mainServiceData, 'attachServices'), i => ({
              id: i.id,
              price: i.price,
            })),
          }
          if (get(this.mainServiceData, 'subService')) {
            data.AppointmentService.AppointmentSubService = get(this.mainServiceData, 'subService', null)
            // data.AppointmentService.id = get(this.mainServiceData, 'subService.id')
            // data.AppointmentService.price = get(this.mainServiceData, 'subService.price')
          }
          others.push(data)
        }
      }

      const date = dayjs(this.mainServiceData.date).format('YYYY-MM-DD')
      // let time = dayjs(this.mainServiceData.time).format('HH:mm')
      // if (isInvalidDate(time)) time = this.mainServiceData.time
      const time = this.mainServiceData.time || '00:00'
      const start = dayjs(`${date} ${time}`).toDate()

      const base = {
        start,
        AppointmentReservations: [],
      }

      // member
      const isMember = get(this.memberData, 'isMember')
      if (isMember) {
        const member = get(this.memberData, 'member')
        if (member) {
          base.Member = {
            id: member.id,
            MTags: map(member.MTags, tag => ({ id: tag.id })),
            SystemMTag: map(member.SystemMTag, tag => ({ id: tag.id })),
          }
        }
      } else {
        base.Member = {
          id: null,
          MTags: [],
          SystemMTag: [],
        }
      }

      if (!others.length) {
        base.AppointmentReservations = [main]
        return base
      }

      base.AppointmentReservations = [main, ...others]
      return base
    },
    showDepositBlock () {
      // if (!this.memberData.isMember) return false
      if (get(this.config, 'chargeType') !== 'deposit') return false
      if (!get(this.depositConfig, 'enable')) return false
      if (!get(this.mainServiceData, 'service')) return false
      if (this.orderDeposit.need && this.useDeposit) return true
      return false
    },
    showServiceClassTicket () {
      // if (!get(this.depositConfig, 'enableClassTicketCredit')) return false
      if (!get(this.mainServiceData, 'service')) return false
      if (this.config.chargeType === 'none') {
        if (get(this.mainServiceData, 'service.requiredClassTicket')) return true
        return false
      }
      if (!get(this.memberData, 'member')) return false
      if (this.peopleCount > 1) return false
      return true
    },
    fillBasicData () {
      // 沒有選服務
      if (!get(this.mainServiceData, 'service')) return false
      // 沒有選時間
      if (!get(this.mainServiceData, 'date') || !get(this.mainServiceData, 'time')) return false
      // 如果服務需要人員但沒選unit
      if (get(this.mainServiceData, 'service.enableAppointmentUnit')) {
        if (!get(this.mainServiceData, 'serviceUnit')) return false
      }
      return true
    },
    orderDiscount () {
      return get(store.state, 'reservationCreate.orderDiscount')
    },
  },
  watch: {
    AppointmentOrder () {
      this.checkOrderDeposit()
      this.calculateCheckoutPrice()
    },
  },
  async mounted () {
    if (this.useDeposit) {
      await Promise.all([
        this.getDepositConfig(),
        this.getDepositPayment(),
        this.getReservationConfig(),
      ])
    } else await this.getReservationConfig()
    // if (this.queryMember) await this.findMember()
    await this.getServiceUnit()
  },
  methods: {
    async getServiceUnit () {
      const res = await GetServiceUnit({ shopId: this.shopId })
      this.allServiceUnitList = res
    },
    calculateCheckoutPrice: async function () {
      if (this.config.chargeType !== 'checkout') return
      const memberId = get(this.memberData, 'member.id') || null
      // if (!memberId) return

      // 沒選服務
      if (!get(this.mainServiceData, 'service')) return
      // 沒選時間
      if (!get(this.mainServiceData, 'date') || !get(this.mainServiceData, 'time')) return
      // 如果服務需要人員但沒選unit
      if (get(this.mainServiceData, 'service.enableAppointmentUnit')) {
        if (!get(this.mainServiceData, 'serviceUnit')) return
      }

      // 回饋金折抵
      const cashbackDiscount = get(store.state, 'reservationCreate.orderDiscount.cashback')

      const reservations = map(this.compactReservation, (item) => {
        const baseData = pick(item, [
          'appointmentServiceId',
          'appointmentSubServiceId',
          'appointmentServiceAttachIds',
        ]) || {}
        return {
          ...baseData,
          useClassTicketRecordId: item.classTicketRecordId,
        }
      })

      if (some(reservations, (item) => !item.appointmentServiceId)) return

      const [res, err] = await ReservationOrderCalculateCheckoutPrice({
        shopId: this.shopId,
        memberId,
        reservations,
        cashbackDeductionAmount: cashbackDiscount,
      })
      this.orderCheckout.price = res.totalPrice
      this.orderCheckout.data = res
    },

    checkOrderDeposit () {
      const orderDeposit = new OrderDeposit(this.AppointmentOrder, this.depositConfig)

      console.log('計算訂金', {
        need: orderDeposit.needDeposit(),
        price: orderDeposit.getDepositPrice() || 0,
      }, this.AppointmentOrder)

      this.orderDeposit = {
        need: orderDeposit.needDeposit(),
        price: orderDeposit.getDepositPrice() || 0,
      }
    },
    async checkReservation (data) {
      const date = dayjs(data.date).format('YYYY/MM/DD')
      const time = dayjs(data.time).format('HH:mm')
      const start = dayjs(`${date} ${time}`)
      if (!start) return

      const [res] = await CheckReservation({
        shopId: this.shopId,
        start: start.toDate(),
        appointmentUnitIds: [get(data, 'serviceUnit.id')],
        appointmentServiceId: get(data, 'service.id'),
        appointmentSubServiceId: get(data, 'subService.id'),
        appointmentServiceAttachIds: map(
          get(data, 'attachServices', []),
          'id',
        ),
        useNotSpecifyRule: false,
        peopleCount: this.peopleCount,
      })
      console.log('detail', res.reservations)
    },
    async getReservationConfig () {
      try {
        const res = await FindReservationParameter({ shopId: this.shopId })
        this.config = res
      } catch (e) {
        // this.$message.error(e)
        console.log(`getReservationConfig error: ${e}`)
      }
    },
    getRef (form, type) {
      console.log('getRef', type, form)
      if (type === 'memberInfo') this.form.member = form
      else if (type === 'serviceInfo') this.form.mainService = form
      else if (type === 'otherService') this.form.otherService = form
      else if (type === 'note') this.form.note = form
      else if (type === 'deposit') this.form.deposit = form
      else if (type === 'classTicket') this.form.classTicket = form
      else if (type === 'invoice') this.form.invoice = form
    },
    async getDepositConfig () {
      const [res, err] = await GetDepositConfig({
        shopId: this.shopId,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.depositConfig = res
    },
    async getDepositPayment () {
      const [res, err] = await GetDepositPayment({
        shopId: this.shopId,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.depositPaymentConfig = res
    },
  },
}
